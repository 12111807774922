import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import homeicon from './img/home5.svg';
import statsicon from './img/stats5.svg';
import menuicon from './img/menu5.svg';
import logouticon from './img/logout5.svg';
import fortotikes from './img/fortotikes.svg';
import upload from './img/upload.svg';
import tracking from './img/tracking.svg';
import contact from './img/contact.svg';
import logo from './img/logo-xit-white.png'
import pins from './img/pins.svg'
import { staticSlice } from '../store/staticSlice';


const Navbar = ({ toggleNav }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.liscence.modules);
  const modulesArray = modules.modules.split(',').map(Number);
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation(); // Get the current location

  const handleLogout = () => {
    const confirmed = window.confirm("Are you sure you want to log out?");

    if (confirmed) {
      // Clear the user's session data
      localStorage.removeItem('token'); // Removed AsyncStorage
      localStorage.removeItem('company');
      localStorage.removeItem('username');
      dispatch(staticSlice.actions.clear());
      navigate('/login');
      alert('You have been logged out.');
    }
  };

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
    toggleNav();
  };

  const handleImageClick = (event) => {
    event.stopPropagation(); // Prevent the navbar from expanding when clicking on the image
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };


  return (
    <nav className={`navbar-menu ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="burger" onClick={toggleNavbar}>
        <img src={menuicon} alt="menu" />
      </div>
      <ul className="navbar__list">
        <li className={`navbar__li-box ${getActiveClass('/dashboar')}`}>
          <Link to="/dashboard">
            <img src={homeicon} alt="Home" onClick={handleImageClick} />
            {isExpanded && <span className="navbar-link">Αρχική</span>}
          </Link>
        </li>
        {modulesArray.includes(1) && (
        <li className={`navbar__li-box ${getActiveClass('/fortotikes')}`}>
          <Link to="/fortotikes">
            <img src={fortotikes} alt="fortotikes" onClick={handleImageClick} />
            {isExpanded && <span className="navbar-link">Λίστα Φορτωτικών</span>}
          </Link>
        </li>
        )}
        {modulesArray.includes(4) && (
        <li className={`navbar__li-box ${getActiveClass('/upload-fortotikes')}`}>
          <Link to="/upload-fortotikes">
            <img src={upload} alt="Upload fortotikes" onClick={handleImageClick} />
            {isExpanded && <span className="navbar-link">Φόρτωση Φορτωτικών</span>}
          </Link>
        </li>
        )}
        {modulesArray.includes(2) && (
        <li className={`navbar__li-box ${getActiveClass('/tracking')}`}>
          <Link to="/tracking">
            <img src={tracking} alt="Tracking" onClick={handleImageClick} />
            {isExpanded && <span className="navbar-link">Tracking</span>}
          </Link>
        </li>
        )}
        {modulesArray.includes(6) && (
        <li className={`navbar__li-box ${getActiveClass('/pins')}`}>
          <Link to="/pins">
            <img src={pins} alt="Pins" onClick={handleImageClick} />
            {isExpanded && <span className="navbar-link">Πυλώνες</span>}
          </Link>
        </li>
        )}
        {modulesArray.includes(4) && (
        <li className={`navbar__li-box ${getActiveClass('/contact')}`}>
          <Link to="/contact">
            <img src={contact} alt="Contact" onClick={handleImageClick} />
            {isExpanded && <span className="navbar-link">Επικοινωνία</span>}
          </Link>
        </li>
        )}
        {modulesArray.includes(3) && (
        <li className={`navbar__li-box ${getActiveClass('/stats')}`}>
          <Link to="/stats">
            <img src={statsicon} alt="Statistics" onClick={handleImageClick} />
            {isExpanded && <span className="navbar-link">Στατιστικά</span>}
          </Link>
        </li>
        )}
        <li className={`navbar__li-box ${getActiveClass('/')}`} onClick={handleLogout}>
          <img src={logouticon} alt="Logout" onClick={handleImageClick} />
          {isExpanded && <span className="navbar-link">Έξοδος</span>}
        </li>
      </ul>
      {isExpanded && (
        <div className="navbar-footer">
          <img src={logo} alt="Company Logo" className="company-logo" />
          <div className="company-info">
            <p>Design & developed by <a href="https://www.xit.gr" target="_blank" rel="noopener noreferrer">XIT</a></p>
            <p>Επικοινωνία: 2108318018</p>
          </div>
        </div>
      )}
    </nav>
  );
};


export default Navbar;