import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { useDispatch } from 'react-redux';
import { liscenceSlice } from '../Liscencing/LiscenceSlice';
import { checkLiscence } from '../Liscencing/CheckLiscence';
import { staticSlice } from '../store/staticSlice';
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";

function Login() {
  Modal.setAppElement('#root');
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [lan, setLan] = useState(localStorage.getItem('appLanguage') || 'gr');
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    setLan(language);
    localStorage.setItem('appLanguage', language);
    // Implement language change logic here, e.g., using i18next
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const liscence = await checkLiscence(username);
      if (liscence.error) {
        setErrorMsg();

        alert(liscence.error_msg);
        return;
      }

      // Store license data in Redux
      dispatch(liscenceSlice.actions.setModules(liscence.liscence));

      const requestBody = {
        service: 'login',
        username,
        password,
        appId: '1001',
        COMPANY: '1000',
        BRANCH: '1000',
        MODULE: '0',
        REFID: '999',
      };

      const response = await axiosInstance.post('/s1services', requestBody);

      if (response.data.success === true) {
        const companyInfo = response.data.companyinfo;

        // Store necessary data in localStorage
        localStorage.setItem('token', response.data.clientID);
        localStorage.setItem('company', companyInfo.split(',')[0]);
        localStorage.setItem('username', username);

        dispatch(staticSlice.actions.setActor(requestBody.REFID));
        dispatch(staticSlice.actions.setCompany(requestBody.COMPANY));
        dispatch(staticSlice.actions.setBranch(requestBody.BRANCH));

        // Navigate to dashboard
        navigate('/dashboard');
      } else {
        setErrorMsg('Failed to login. Check your credentials!');
        alert('Failed to login. Check your credentials!');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMsg('Failed to login. Check your credentials!');
      alert('Failed to login. Check your credentials!');
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="logo-container">
                <h1 className="site-name">Mεταφορικό-Xit</h1>
                <img src="/logo.jpg" alt="Logo" className="logo" />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">{t("username")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">{t("password")}</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button
                  onClick={() => setModalVisible(true)}
                  style={{
                    padding: '10px',
                    alignSelf: 'flex-end',
                    background: 'none',
                    border: 'none',
                  }}
                >
                  <span
                    style={{
                      textAlign: 'right',
                      textDecoration: 'underline',
                      color: 'green',
                      fontWeight: 'bold',
                      fontSize: '15px',
                    }}
                  >
                    Change language
                  </span>
                </button>
                <button type="submit" className="btn btn-primary">Login</button>
                {errorMsg && <p className="text-danger">{errorMsg}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        style={{
          content: {
            maxHeight: '50vh',
            maxWidth: '90vw',
            minWidth: '70vw',
            margin: 'auto',
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '8px',
            border: '1px solid #ccc',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <button
            onClick={() => {
              changeLanguage('gr');
              setModalVisible(false);
            }}
            className="modalBtn"
            style={{
              borderColor: lan === 'gr' ? 'green' : 'gray',
              backgroundColor: lan === 'gr' ? 'green' : 'white',
              color: lan === 'gr' ? 'white' : 'black',
            }}
          >
            Greek
          </button>
          <button
            onClick={() => {
              changeLanguage('ro');
              setModalVisible(false);
            }}
            className="modalBtn"
            style={{
              borderColor: lan === 'ro' ? 'green' : 'gray',
              backgroundColor: lan === 'ro' ? 'green' : 'white',
              color: lan === 'ro' ? 'white' : 'black',
            }}
          >
            Romanian
          </button>
          <button
            onClick={() => {
              changeLanguage('en');
              setModalVisible(false);
            }}
            className="modalBtn"
            style={{
              borderColor: lan === 'en' ? 'green' : 'gray',
              backgroundColor: lan === 'en' ? 'green' : 'white',
              color: lan === 'en' ? 'white' : 'black',
            }}
          >
            English
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Login;