import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NavBar from "../Navbar/Navbar.js";
import Header from '../Header/Header.js';
import { useUser } from '../UserContext';
import * as XLSX from 'xlsx';
import axiosInstance from '../axiosConfig.js';
import './Fortotikes.css'; // Ensure your CSS is imported
import Multiselect from "multiselect-react-dropdown";

function ListaFortotikon() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const staticData = useSelector((state) => state.static.static);
  const stateOptions = [
    { name: "10", id: "10" },
    { name: "20", id: "20" },
    { name: "30", id: "30" }
  ];

  const [fortotikes, setFortotikes] = useState([]);
  const [filters, setFilters] = useState({
    state: [],
    fromdate: getCurrentMonthStart(),
    todate: getCurrentMonthEnd(),
  });
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 20;

  // Function to get the first day of the current month in YYYY-MM-DD
  function getCurrentMonthStart() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01`;
  }

  // Function to get the last day of the current month in YYYY-MM-DD
  function getCurrentMonthEnd() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    return `${year}-${String(month).padStart(2, '0')}-${String(lastDay).padStart(2, '0')}`;
  }

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    loadFortotikes();
  }, [filters, currentPage]);

  const formatDateForAPI = (dateStr) => {
    return dateStr.replace(/-/g, '');
  };

  const getFortotikes = async () => {
    const thisClientID = localStorage.getItem("token");
    const parameters = {
      service: "SqlData",
      clientID: thisClientID,
      appId: "1001",
      SqlName: "10021",
      company: staticData.company,
      fromdate: formatDateForAPI(filters.fromdate),
      todate: formatDateForAPI(filters.todate),
      finstates: filters.state.length ? filters.state.map(s => s.id).join(",") : stateOptions.map(s => s.id).join(","),  // Join selected state ids with commas
      actor: staticData.actor,
    };

    try {
      const response = await axiosInstance.post('/s1services', parameters);
      return response;
    } catch (error) {
      console.error("Error fetching fortotikes:", error);
      alert("There was an error fetching the data.");
      return null;
    }
  };

  const loadFortotikes = async () => {
    const resp = await getFortotikes();
    if (resp && resp.data.success) {
      const startIndex = (currentPage - 1) * recordsPerPage;
      const paginatedRecords = resp.data.rows.slice(startIndex, startIndex + recordsPerPage);
      setFortotikes(paginatedRecords);
    } else if (resp && resp.data.error) {
      alert(resp.data.error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleStateSelect = (selectedList) => {
    setFilters({
      ...filters,
      state: selectedList,
    });
  };

  const handleStateRemove = (selectedList) => {
    setFilters({
      ...filters,
      state: selectedList,
    });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(fortotikes);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoices');
    XLSX.writeFile(workbook, 'invoices.xlsx');
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div className={`container-fortotikes ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Invoices</h1>
          <div className="filters-fortotikes">
            <label>From Date:</label>
            <input
              type="date"
              name="fromdate"
              value={filters.fromdate}
              onChange={handleFilterChange}
              className="filter-input"
            />
            <label>To Date:</label>
            <input
              type="date"
              name="todate"
              value={filters.todate}
              onChange={handleFilterChange}
              className="filter-input"
            />
            <Multiselect
              options={stateOptions}
              selectedValues={filters.state}
              onSelect={handleStateSelect}
              onRemove={handleStateRemove}
              displayValue="name"
              className="filter-input muilti-selection"
            />
            <button className="filter-button" onClick={loadFortotikes}>Εφαρμογή Φίλτρων</button>
            <button className="export-button" onClick={exportToExcel}>Εξαγωγή σε Excel</button>
          </div>
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Tracking Nr</th>
                  <th>Κατάσταση</th>
                  <th>Ημερομ. Διανομής</th>
                  <th>Ημερομ. Καταχώρησης</th>
                  <th>Αποστολέας</th>
                  <th>Παραλήπτης</th>
                  <th>Εντολέας</th>
                  <th>Διεύθυνση</th>
                  <th>Τηλέφωνο</th>
                  <th>ΑΦΜ</th>
                  <th>Κόμιστρα</th>
                  <th>Αντικαταβολές</th>
                </tr>
              </thead>
              <tbody>
                {fortotikes.map((fortotiki) => (
                  <tr key={fortotiki.findoc}>
                    <td>{fortotiki.findoc}</td>
                    <td>{fortotiki.finstates}</td>
                    <td>{fortotiki.deliveryDate || '-'}</td>
                    <td>{fortotiki.entryDate || '-'}</td>
                    <td>{fortotiki.sender || '-'}</td>
                    <td>{fortotiki.name || '-'}</td>
                    <td>{fortotiki.orderer || '-'}</td>
                    <td>{fortotiki.address || '-'}</td>
                    <td>{fortotiki.phone || '-'}</td>
                    <td>{fortotiki.vat || '-'}</td>
                    <td>{fortotiki.freightCharges || '-'}</td>
                    <td>{fortotiki.cashOnDelivery || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button onClick={prevPage} disabled={currentPage === 1}>
              Προηγούμενη
            </button>
            <span>Page {currentPage}</span>
            <button onClick={nextPage} disabled={fortotikes.length < recordsPerPage}>
              Επόμενη
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListaFortotikon;