import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider } from './UserContext';
import LoginPage from './Login/Login.js';
import Dashboard from './Dashboard/Dashboard.js';
import ListaFortotikon from './ListaFortotikon/Fortotikes.js';
import Tracking from './Tracking/Tracking.js';
import StatisticsPage from './Statistics/Statistics.js';
import Contact from './Contact/Contact.js';
import Upload from './Upload/Upload.js';
////import CreatePins from "./CreatePin/PinDashboard.js"
import MapWithMarkers from "./CreatePin/CreatePin2.js"
import ProtectedRoute from './ProtectedRoute';


function App() {
  console.log('Rendering App component');
  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route path="/fortotikes" element={<ListaFortotikon />} />
            <Route path="/tracking" element={<Tracking />} />
            <Route path="/stats" element={<StatisticsPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/upload-fortotikes" element={<Upload />} />
            <Route path="/pins" element={<MapWithMarkers />} />
            <Route path="/" element={<Navigate to="/login" />} />
            {/* Add more routes here */}
          </Routes>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
