import { combineReducers } from 'redux';
import liscenceSlice from '../Liscencing/LiscenceSlice';
import staticSlice from './staticSlice';

const rootReducer = combineReducers({
  liscence: liscenceSlice,
  static: staticSlice,
  // Add other reducers here
});

export default rootReducer;