import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import axiosInstance from '../axiosConfig.js'; // Adjust this import based on your project structure
import Navbar from "../Navbar/Navbar.js";
import './CreatePin2.css';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const MapWithMarkers = () => {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const [center, setCenter] = useState({ lat: 37.983810, lng: 23.727539 }); // Default center
  const mapRef = useRef(null);

  // Form state
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [name, setName] = useState('');
  const [isUpdate, setIsUpdate] = useState(false); // Track if updating an existing pin
  const [errors, setErrors] = useState({}); // Track validation errors

  // Static categories and types
  const categories = [
    { id: '1', name: 'Category 1' },
    { id: '2', name: 'Category 2' },
    { id: '3', name: 'Category 3' }
  ];

  const types = [
    { id: 'A', name: 'Type A' },
    { id: 'B', name: 'Type B' },
    { id: 'C', name: 'Type C' }
  ];

  // Fetching pins from the API
  const getPins = async () => {
    const thisClientID = localStorage.getItem("token");
    const parameters = {
      service: "SqlData",
      clientID: thisClientID,
      appId: "1001",
      SqlName: "7100"
    };

    const response = await axiosInstance.post('/s1services', parameters);
    return response;
  };

  const loadPins = async () => {
    getPins()
      .then((resp) => {
        if (resp?.data.success) {
          setMarkerData(resp.data.rows);
        } else {
          alert("Error", resp?.error);
        }
      })
      .catch((e) => {
        alert("Error", e);
      });
  };

  useEffect(() => {
    loadPins();
  }, []);

  useEffect(() => {
    if (mapRef.current && markerData.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markerData.forEach(pin => {
        bounds.extend({ lat: parseFloat(pin.latitude), lng: parseFloat(pin.longitude) });
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [markerData]);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setLat(marker.latitude);
    setLng(marker.longitude);
    setName(marker.name);
    setIsUpdate(true); // Enable update mode
    setErrors({}); // Clear errors when a marker is selected
  };

  const validateForm = () => {
    const errors = {};

    if (!lat) {
      errors.lat = 'Latitude is required';
    }
    if (!lng) {
      errors.lng = 'Longitude is required';
    }
    if (!name) {
      errors.name = 'Name is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const saveOrUpdatePin = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    
    const thisClientID = localStorage.getItem("token");

    const requestBody = {
      service: "setData",
      clientID: thisClientID, 
      appId: "1001",
      OBJECT: "PRJC",
      KEY: isUpdate ? selectedMarker.TYPEID : "", // Use marker code if updating
      data: {
        PRJC: [
          {
            CODE: isUpdate ? selectedMarker.code : name, // Name as code
            NAME: name, // Name as name
            PRJCATEGORY: isUpdate ? selectedMarker.CATEGID : "1" // Static category ID for insert, replace as needed
          }
        ],
        PRJEXTRA: [
          {
            VARCHAR01: lat.toString(), // Latitude as string
            VARCHAR02: lng.toString(), // Longitude as string
            UTBL01: isUpdate ? selectedMarker.type : "A" // Static type ID, replace as needed
          }
        ]
      }
    };

    try {
      const response = await axiosInstance.post('/s1services', requestBody);
      if (response.data.success) {
        alert(isUpdate ? 'Pin updated successfully!' : 'Pin created successfully!');
        loadPins(); // Reload pins after creating/updating a pin
        setIsUpdate(false); // Reset update mode
        setLat('');
        setLng('');
        setName('');
      } else {
        alert('Error saving pin:', response.data.error);
      }
    } catch (error) {
      console.error('Error saving pin:', error);
      alert('Error saving pin!');
    }
  };

  return (
    <div className='container-pin-create'>
      <div className="navbar-left-stats">
        <Navbar />
      </div>

      <div className="controls-pin-create">
        <h2>Χαρακτηριστικά Πυλώνα</h2>
        <input className='input-pin-text'
          type="text"
          placeholder="Latitude"
          value={lat}
          onChange={(e) => setLat(e.target.value)}
        />
        {errors.lat && <div className="error">{errors.lat}</div>}

        <input className='input-pin-text'
          type="text"
          placeholder="Longitude"
          value={lng}
          onChange={(e) => setLng(e.target.value)}
        />
        {errors.lng && <div className="error">{errors.lng}</div>}

        <input className='input-pin-text'
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name && <div className="error">{errors.name}</div>}

        <button className="btn-savepin" onClick={saveOrUpdatePin}>
          {isUpdate ? 'Update' : 'Save'}
        </button>
      </div>

      <LoadScript googleMapsApiKey="AIzaSyCULbS7zTg-1cmZ9nLKSAL48V-qE0JYZyc">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
          onLoad={map => mapRef.current = map}
        >
          {markerData.map((marker, index) => (
            <Marker
              key={index}
              position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
              onClick={() => handleMarkerClick(marker)}
              onMouseOver={() => setActiveMarker(marker)}
              onMouseOut={() => setActiveMarker(null)}
            >
              {activeMarker === marker && (
                <InfoWindow
                  position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                  onCloseClick={() => setActiveMarker(null)}
                >
                  <div>
                    <strong>{marker.name}</strong><br />
                    Lat: {parseFloat(marker.latitude).toFixed(4)}, Lng: {parseFloat(marker.longitude).toFixed(4)}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapWithMarkers;
