import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  static: {
    company: '',
    actor: '',
    url: '',
    mobile: '',
    branch: '',
  },
};

export const staticSlice = createSlice({
  name: 'static',
  initialState: initialState,
  reducers: {
    setCompany: (state, action) => {
      state.static.company = action.payload;
    },
    setUrl: (state, action) => {
      state.static.url = action.payload;
    },
    setActor: (state, action) => {
      state.static.actor = action.payload;
    },
    setMoblie: (state, action) => {
      state.static.mobile = action.payload;
    },
    setBranch: (state, action) => {
      state.static.branch = action.payload;
    },
    clear: (state) => {
      state.static.actor = '';
      state.static.url = '';
      state.static.company = '';
      state.static.branch = '';
    },
  },
});

export const { setCompany, setUrl, setActor, setMoblie, setBranch, clear } = staticSlice.actions;
export default staticSlice.reducer;