import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Uses localStorage
import rootReducer from './reducers'; // Adjust the path to your combined reducers

// Persistence configuration
const persistConfig = {
  key: 'root', // Key for storage
  storage,
};

// Configure persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = createStore(persistedReducer);

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };