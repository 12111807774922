import React from 'react';
import './Header.css';
import { FaUserCircle } from 'react-icons/fa';

const Header = ({ username, companyName }) => {
    return (
      <div className="header">
        <div className="company-name">{companyName}</div>
        <div className="user-info">
          <FaUserCircle className="user-icon" />
          <span>{username}</span>
        </div>
      </div>
    );
  }
  
  export default Header;
