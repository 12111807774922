import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import './Upload.css';
import NavBar from "../Navbar/Navbar";
import Header from '../Header/Header';
import { useUser } from '../UserContext';

function Upload() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const [data, setData] = useState([]);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setData(jsonData);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div className={`container-upload ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Upload Excel File</h1>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
          {data.length > 0 && (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    {Object.keys(data[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, i) => (
                        <td key={i}>{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Upload;
