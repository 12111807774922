// src/store/liscenceSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modules: {
    soaction: 0,
    apostoles: 0,
    paralaves: 0,
    monitor: 0,
    crm: 0,
    packing: 0,
    ftpaddress: '',
    ftppassword: '',
    ftpport: '',
    ftpuser: '',
    modules: '',
    WebApp: false
  },
};

export const liscenceSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setModules: (state, action) => {
      state.modules.soaction = action.payload.soaction;
      state.modules.apostoles = action.payload.apostoles;
      state.modules.paralaves = action.payload.paralaves;
      state.modules.monitor = action.payload.Monitor;
      state.modules.crm = action.payload.crm;
      state.modules.packing = action.payload.packing;
      state.modules.ftpaddress = action.payload.ftpaddress;
      state.modules.ftppassword = action.payload.ftppassword;
      state.modules.ftpport = action.payload.ftpport;
      state.modules.ftpuser = action.payload.ftpuser;
      state.modules.modules = action.payload.modules;
      state.modules.WebApp = action.payload.WebApp;
    },
  },
});

export const { setModules } = liscenceSlice.actions;
export default liscenceSlice.reducer;
