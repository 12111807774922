// src/axiosConfig.js
import axios from 'axios';

/////https://cors-anywhere.herokuapp.com/

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: 'https://xitdevelop.oncloud.gr',
  headers: {
    'Content-Type': 'application/json',
  }
});

// Request interceptor to log the URL and other details before the request is sent
axiosInstance.interceptors.request.use(
  function(config) {
    console.log('Request URL:', config.url);
    console.log('Request Headers:', config.headers);
    console.log('Request Data:', config.data);
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Optionally, you could add a response interceptor to handle or log responses globally
axiosInstance.interceptors.response.use(
  function(response) {
    console.log('Response Data:', response.data);
    return response;
  },
  function(error) {
    console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;